.projects {
  width: 80%;
  /* height: 100vh; */
  height: 100%;
  min-height: 100% !important;
  margin: 0 auto;
  padding-bottom: 20px;
  display: flex;
  margin-bottom: 10px;
}

.project-title {
  padding-bottom: 50px;
}

.project-list {
  /* Center projects */
  margin: auto;
}

/* Setting Modal */

.mod-subtitle {
  font-size: 100%;
}

.link-button {
  background-color: #6c757d;
  border-color: #6c757d;
  color: black;
  border-radius: 0.25rem;
}

/* Setting Project Images and Overlay */
.project-image {
  position: relative;
}

.project-image-img {
  display: block;
  width: 100%;
}

.project-row {
  row-gap: 10px;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.p-title {
  font-size: 2em;
  font-weight: bold;
}

.p-kw {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.img-overlay:hover {
  opacity: 1;
  cursor: pointer;
}
