html {
  scroll-behavior: smooth;
}

.App {
  min-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

footer {
  width: 100%;
  height: 200px;
  background-color: #161e29;
  display: flex;
  align-items: center;
  justify-content: center;
}


.footer-content {
  max-width: 80%;
}

.footer-social {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}

.footer-social a {
  color: #fff;
}

.footer-social a:hover {
  color: rgb(201, 200, 200);
}

.footer-icon {
  font-size: 80px;
}

.footer-links ul {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}

.footer-links  ul  li {
  list-style: none;
  color: #fff;
  font-size: 2rem;
}

.footer-links  ul  li:hover, .footer-links  ul  a:hover {
  color: rgb(201, 200, 200);
  text-decoration: none;
}

.footer-copyrights {
  color: #fff;
}


@media only screen and (max-width: 576px) {
  .footer-links  ul  li {
    font-size: 0.8rem;
  }

  [class^="bi"] { 
    width: 20px;
    height: 20px;
  }
  .footer-copyrights {
    font-size: 0.8rem;
  }
}


