.skills {
  @apply w-[80%] flex flex-1 m-7 justify-center;
}

.skills-list {
  @apply grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-x-5 justify-between;
  @apply text-2xl;
}
.skills-menu {
  @apply list-none max-w-[250px] w-1/2 md:w-1/3 flex flex-col flex-none;
  @apply bg-[#161e29] text-[#FFF] text-lg font-semibold;
}

.skills-menu-item .active {
  @apply bg-slate-600;
}
.skills-menu-item {
  @apply flex flex-col;
}
.skills-menu-item > li {
  @apply hover:bg-slate-600 cursor-pointer p-2 px-4 overflow-auto;
}
.skills-display {
  @apply max-w-[500px] w-1/3 2xl:w-full bg-[#5e6d83] flex-1 p-3;
  @apply overflow-auto;
}

.lang-grid {
  @apply flex flex-row items-center gap-x-5 flex-wrap justify-between;
  @apply text-start;
}
.lang-grid > ul {
  @apply flex;
}
.lang {
  @apply flex w-full items-center flex-wrap gap-y-3 justify-center;
  @apply md:justify-between md:gap-x-3;
}

.lang-container {
  @apply flex flex-col items-center gap-y-0;
  @apply hover:text-slate-200;
}
.lang i {
  @apply text-white;
  @apply hover:text-slate-200;
}

/* Icon settings */
.m-icon {
  font-size: 70px;
}
