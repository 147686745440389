.App-header {
  min-height: 100%;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-brand {
  color: #fff;
  font-size: 1.5rem;
}

.navLink:visited {
  color: white;
}

.navLink:active,
.navbar-light .navbar-nav .nav-link.active {
  color: white;
}

.navLink:hover, .header-social:hover, .nav-link:hover, .navbar-brand:hover {
  background-color: transparent;
  color: rgb(201, 200, 200) !important;
}

.nav {
  background-color: transparent;
}

.nav.active {
  background-color: #161e29;
}

.nav-icon {
  font-size: 50px;
}

.nav-icon:hover {
  color: rgb(201, 200, 200);
}

.custom-toggler.navbar-toggler {
  background-image: 
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(280, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); 
}

.painting-background {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)),
    url("../public/jb-bg.jpg");
  background-size: cover;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  margin-bottom: 0px;
}

.jb-text {
  font-size: 70pt;
  margin: auto;
  padding: 90px;
  border: 1px solid;
  animation: fadeIn ease 5s;
  background-color: rgba(0, 0, 0, 0.4);
}

.learn-more {
  margin: auto;
}

button.learn-more {
  margin: auto;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  font-weight: bold;
  border: 1px solid;
  color: white;
  min-height: 40px;
}

.learn-more:hover {
  color: rgb(201, 200, 200) !important;
  border: 1px solid rgb(201, 200, 200);
  font-weight: bolder;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 576px) {
  .jb-text {
    font-size: 0.5em;
  }
  .painting-background {
    font-size: 0.5em;
  }
  .mnlink {
    display: flex;
    flex-direction: row;
  }
  a.nav-link-button.nav-link {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .jb-text {
    font-size: 5em;
  }
}
