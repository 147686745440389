@import url("https://cdn.jsdelivr.net/gh/devicons/devicon@v2.10.1/devicon.min.css");

.about {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  /* height: 100vh; */
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  padding-bottom: 100px;
}

.abt-title {
  margin: 0 auto;
  width: 80%;
}

.about-row {
  margin: auto;
  width: 80%;
}

.profile {
  display: flex;
}

.profile-image {
  margin: auto;
  min-width: 200px;
  height: auto;
}

.about ul li {
  list-style: none;
  margin: 0;
}

@media only screen and (max-width: 576px) {
  .about {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .profile-image {
    max-width: 300px;
    height: auto;
  }
}
